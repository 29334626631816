import axios from 'axios';

import appSettings from '../app-settings';

const API_URL = `${appSettings.baseUrl}/accounts/subscription`;

export const getInvoices = async () => {
  const res = await axios.get(`${API_URL}/invoices`);
  return res.data;
};
