import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { IconAlertCircleFilled } from '@tabler/icons-react';
import { Tooltip } from '@mui/material';
import { useIsMounted } from 'usehooks-ts';

import { useTags } from '../../../store/tags/hook';
import { useCampaigns } from '../../../store/campaigns/hooks';
import { useSegments } from '../../../store/segments/hook';
import Spinner from '../../loader/Spinner';
import SwitchInput from '../../inputs/SwitchInput';
import { getNetworkError } from '../../../core/utils';
import { getAudienceSizeCount } from '../../../api';
import { AutocompleteInput } from 'ui';

const DEBOUNCE_DELAY_TIME_MS = 750;

const MAX_CONDITIONS = 5;

const getSegmentType = (segment) => {
  if (
    segment.rules.some(
      (rule) => rule.field.includes('jnct_campaign_label.') || rule.field.includes('last_interaction_at'),
    )
  ) {
    return 'Segments - Activity & Labels';
  }

  if (segment.rules.some((rule) => rule.field.includes('donation'))) {
    return 'Segments - Donations';
  }

  if (segment.rules.some((rule) => rule.field.includes('import_filename'))) {
    return 'Segments - Source';
  }

  return 'Segments - Other';
};

const ToForm = ({ disabled }) => {
  const { updateCampaignDetails, campaignDetails, setError } = useCampaigns();
  const [excludeContacts, setExcludeContacts] = useState(
    () => !!(campaignDetails.exclude?.segmentIds?.length || campaignDetails.exclude?.tagIds?.length),
  );
  const [audienceSize, setAudienceSize] = useState(0);
  const [loading, setLoading] = useState(false);
  const [audienceSizeWarning, setAudienceSizeWarning] = useState(false);
  const { tags } = useTags();
  const { segments } = useSegments();
  const [selectedAudience, setSelectedAudience] = useState(() => {
    const audience = [
      ...(campaignDetails.sendTo.tagIds ?? []).map((id) => {
        const tag = tags.find((tag) => tag.id === id);
        return { value: id, label: tag?.name, type: 'tag' };
      }),
      ...(campaignDetails.sendTo.segmentIds ?? []).map((id) => {
        const segment = segments.find((segment) => segment.segmentId === id);
        return { value: id, label: segment?.name, type: 'segment' };
      }),
    ];
    if (campaignDetails.segmentId) {
      const segment = segments.find((segment) => segment.segmentId === campaignDetails.segmentId);
      audience.push({ value: segment.segmentId, label: segment.name, type: 'segment' });
    }
    if (campaignDetails.sendTo.all) {
      audience.push({ value: 'all', label: 'All Contacts', type: 'All' });
    }
    return audience;
  });
  const [excludedSegments, setExcludedSegments] = useState(() => [
    ...(campaignDetails.exclude?.segmentIds ?? []).map((id) => {
      const segment = segments.find((segment) => segment.segmentId === id);
      return { value: id, label: segment?.name, type: 'segment' };
    }),
  ]);
  const [excludedTags, setExcludedTags] = useState(() => [
    ...(campaignDetails.exclude?.tagIds ?? []).map((id) => {
      const tag = tags.find((tag) => tag.id === id);
      return { value: id, label: tag?.name, type: 'tag' };
    }),
  ]);
  const isMounted = useIsMounted();
  const stringifiedLabels = JSON.stringify(campaignDetails.labels || []); // https://github.com/facebook/react/issues/14476#issuecomment-471199055

  const audience = useMemo(() => {
    return {
      all: !!selectedAudience.some((v) => v.type === 'All'),
      tagIds: selectedAudience
        .filter((audience) => !!tags.find((tag) => tag.id === audience.value))
        .map((audience) => audience.value),
      segmentIds: selectedAudience
        .filter((audience) => !!segments.find((segment) => segment.segmentId === audience.value))
        .map((audience) => audience.value),
      excludeSegmentIds: excludedSegments.map((audience) => audience.value),
      excludeTagIds: excludedTags.map((audience) => audience.value),
    };
  }, [selectedAudience, tags, segments, excludedSegments, excludedTags]);

  const updateCampaignAudience = useCallback(() => {
    if (disabled) return;
    const update = {
      sendTo: {
        all: audience.all,
        tagIds: audience.tagIds,
        segmentIds: audience.segmentIds,
      },
      exclude: {
        tagIds: audience.excludeTagIds,
        segmentIds: audience.excludeSegmentIds,
      },
    };
    updateCampaignDetails(update);
  }, [audience, updateCampaignDetails, disabled]);

  const getAudienceSize = useCallback(async () => {
    if (disabled) return;
    if (!selectedAudience.length && !excludedSegments.length && !excludedTags.length) {
      if (isMounted()) setAudienceSize(0);
      return;
    }
    if (isMounted()) setLoading(true);
    try {
      const audienceCount = await getAudienceSizeCount({
        segmentIds: audience.segmentIds,
        tagIds: audience.tagIds,
        excludeSegmentIds: audience.excludeSegmentIds,
        excludeTagIds: audience.excludeTagIds,
        campaignLabelIds: JSON.parse(stringifiedLabels),
      });
      if (isMounted()) {
        setAudienceSize(audienceCount);
        setAudienceSizeWarning(audienceCount === 0);
      }
    } catch (err) {
      if (isMounted()) setError(getNetworkError(err));
    }
    if (isMounted()) setLoading(false);
  }, [
    disabled,
    selectedAudience.length,
    excludedSegments.length,
    excludedTags.length,
    isMounted,
    audience.segmentIds,
    audience.tagIds,
    audience.excludeSegmentIds,
    audience.excludeTagIds,
    stringifiedLabels,
    setError,
  ]);

  const getCampaignAudienceSize = useCallback(() => {
    updateCampaignAudience();
    getAudienceSize();
  }, [updateCampaignAudience, getAudienceSize]);

  const debouncedGetAudienceSize = useMemo(
    () => debounce(getCampaignAudienceSize, DEBOUNCE_DELAY_TIME_MS),
    [getCampaignAudienceSize],
  );

  useEffect(() => {
    debouncedGetAudienceSize();
  }, [debouncedGetAudienceSize]);

  const audienceOptions = useMemo(() => {
    return [
      ...segments
        .map((segment) => ({ value: segment.segmentId, label: segment.name, type: getSegmentType(segment) }))
        .sort((a, b) => (a.type === b.type ? a.label.localeCompare(b.label) : a.type.localeCompare(b.type))),
      ...tags
        .map((tag) => ({ value: tag.id, label: tag.name, type: 'Tags' }))
        .sort((a, b) => a.label.localeCompare(b.label)),
      { value: 'all', label: 'All Contacts', type: 'All' },
    ];
  }, [tags, segments]);

  // "All Contacts" is not considered to be a condition
  const totalConditions = [...selectedAudience.filter((sA) => sA.type !== 'All'), ...excludedSegments, ...excludedTags]
    .length;

  const handleExcludeContacts = (exclude) => {
    if (disabled) return;
    setExcludeContacts(exclude);
    if (!exclude) {
      setExcludedTags([]);
      setExcludedSegments([]);
    }
  };

  const handleSelectAudience = (values) => {
    if (disabled) return;

    let audience = values;

    const allAdded = values.slice(-1)[0]?.type === 'All';
    const otherAddedWhileAllSelected = values.some((v) => v.type === 'All');

    if (allAdded) {
      audience = [{ value: 'all', label: 'All Contacts', type: 'All' }];
    } else if (otherAddedWhileAllSelected) {
      audience = values.filter((v) => v.type !== 'All');
    }

    if (!audience.length && (excludedSegments.length || excludedTags.length)) {
      audience = [{ value: 'all', label: 'All Contacts', type: 'All' }];
    }

    if (
      !values.some((v) => v.type === 'All') &&
      audience.length > selectedAudience.length &&
      totalConditions === MAX_CONDITIONS
    ) {
      if (audience.length !== 2) {
        return;
      }
      audience = audience.slice(-1);
    }

    setSelectedAudience(audience);
    setExcludedSegments((prevState) => prevState.filter((eS) => !audience.some((a) => a.value === eS.value)));
    setExcludedTags((prevState) => prevState.filter((eT) => !audience.some((a) => a.value === eT.value)));
  };

  const updateExcludedAudience = (excludedIds) => {
    setSelectedAudience((prevState) => {
      if (!prevState.length) return ['all'];
      return prevState.filter((sA) => !excludedIds.includes(sA));
    });
  };

  const handleSelectExcludedSegments = (values) => {
    if (disabled || (values.length > excludedSegments.length && totalConditions === MAX_CONDITIONS)) {
      return;
    }

    setExcludedSegments(values);
    updateExcludedAudience(values);
  };

  const handleSelectExcludedTags = (values) => {
    if (disabled || (values.length > excludedTags.length && totalConditions === MAX_CONDITIONS)) {
      return;
    }

    setExcludedTags(values);
    updateExcludedAudience(values);
  };

  return (
    <form className="space-y-4">
      <div className="flex w-full space-x-6">
        <AutocompleteInput
          className="w-2/3"
          id="audience"
          label="Select Audience"
          placeholder="Type to search for segments or tags"
          multiple={true}
          options={audienceOptions}
          onChange={handleSelectAudience}
          value={selectedAudience}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          groupBy={(option) => option.type}
          disabled={disabled}
        />

        {!disabled && (
          <div className="flex flex-1 items-center space-x-2">
            <div>
              <p className="text-base italic">Estimated audience size:</p>
              <p className="text-base font-semibold">{audienceSize} contacts</p>
            </div>
            {loading && <Spinner className="size-5" />}
            {!loading && !!selectedAudience.length && audienceSizeWarning && (
              <Tooltip title="Campaign should have an audience of more than 0" arrow>
                <IconAlertCircleFilled size={20} className="size-5 text-orange-500" />
              </Tooltip>
            )}
          </div>
        )}
      </div>

      <SwitchInput
        label={excludeContacts ? 'Exclude the following:' : 'Do not exclude any segments'}
        checked={excludeContacts}
        onChange={handleExcludeContacts}
        disabled={disabled}
      />

      {excludeContacts && (
        <div className="grid w-full grid-cols-2 gap-3">
          <AutocompleteInput
            id="segment"
            label="Segments"
            placeholder="Type to search for segments"
            multiple={true}
            options={audienceOptions.filter((audience) => audience.type.includes('Segments'))}
            value={excludedSegments}
            onChange={handleSelectExcludedSegments}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            groupBy={(option) => option.type}
            disabled={disabled}
          />

          <AutocompleteInput
            id="tag"
            label="Tags"
            placeholder="Search for tags"
            multiple={true}
            options={audienceOptions.filter((audience) => audience.type.includes('Tags'))}
            value={excludedTags}
            onChange={handleSelectExcludedTags}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            disabled={disabled}
          />
        </div>
      )}

      <div className="!mt-1.5">
        <p className="text-base italic">You can only select up to {MAX_CONDITIONS} conditions.</p>
        {totalConditions === MAX_CONDITIONS && (
          <p className="text-base font-semibold italic">{totalConditions} selected conditions</p>
        )}
      </div>
    </form>
  );
};

ToForm.propTypes = {
  disabled: PropTypes.bool,
};

ToForm.defaultProps = {
  disabled: false,
};

export default ToForm;
