/* eslint-disable react/prop-types */
import { useMemo } from 'react';
import { useReactTable, getCoreRowModel, getPaginationRowModel, flexRender } from '@tanstack/react-table';
import PropTypes from 'prop-types';

import { Button } from 'ui';
import { formatDate, parseDonationAmount } from 'common/utils';

import InvoiceStatusLabel from './InvoiceStatusLabel';
import CustomPagination from '../tables/CustomPagination';

const InvoicesTable = ({ data }) => {
  const columns = useMemo(
    () => [
      {
        accessorKey: 'total',
        header: () => 'Amount',
        footer: (props) => props.column.id,
        cell: (info) => parseDonationAmount(info.getValue() / 100),
      },
      {
        accessorKey: 'customer_email',
        header: () => 'Sent To',
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'due_date',
        header: () => 'Date Due',
        footer: (props) => props.column.id,
        cell: (info) => formatDate(info.getValue() * 1000),
      },
      {
        accessorKey: 'status',
        header: () => 'Status',
        footer: (props) => props.column.id,
        cell: (info) => <InvoiceStatusLabel dueDate={info.row.original.due_date} status={info.getValue()} />,
      },
      {
        accessorKey: 'hosted_invoice_url',
        header: () => '',
        footer: (props) => props.column.id,
        cell: (info) => {
          // hosted_invoice_url is null when invoice status is draft
          // invoice automatically "finalizes" after 1 hour and hosted_invoice_url is generated
          // invoice can also be manually sent/finalized via the Stripe dashboard or API
          const status = info.row.original.status;
          return (
            info.getValue() && (
              <Button
                title={status === 'paid' ? 'View' : 'View or Pay'}
                href={info.getValue()}
                target="_blank"
                rel="noreferrer"
                size="sm"
                as="link"
              />
            )
          );
        },
      },
    ],
    [],
  );

  const table = useReactTable({
    data,
    columns,
    pageCount: data.totalPages,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const emptyRows = 3 - data.length;

  return (
    <div className="space-y-6">
      <div className="relative overflow-x-auto sm:rounded-lg">
        <table className="data-table w-full text-left text-base text-gray-950 dark:text-gray-400">
          <thead className="bg-white-100 text-sm uppercase text-gray-400 dark:bg-gray-700 dark:text-gray-400">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id} colSpan={header.colSpan} className="px-4 py-3">
                      {header.isPlaceholder ? null : (
                        <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr
                  key={row.id}
                  className="border-b border-gray-50 bg-white-100 hover:bg-gray-10 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600"
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id} className="px-4 py-2">
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {emptyRows > 0 &&
              Array(emptyRows)
                .fill('')
                .map((row, index) => (
                  <tr
                    key={`emptyRow-${index}`}
                    className="h-[52px] border-b border-gray-50 bg-white-100 hover:bg-gray-10 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600"
                  >
                    <td className="px-4 py-2" colSpan={15}></td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>

      {data.length > table.getState().pagination.pageSize && <CustomPagination table={table} total={data.length} />}
    </div>
  );
};

InvoicesTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default InvoicesTable;
