import { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import EmailEditor from 'react-email-editor';
import isEmpty from 'lodash.isempty';
import { IconChevronDown } from '@tabler/icons-react';
import Collapse from '@mui/material/Collapse';
import * as Sentry from '@sentry/react';

import { Button, Loader } from 'ui';
import { noop } from 'common/utils';

import { useAccount } from '../../store/account/hooks';
import {
  EMAIL_SECTION_KEY_NAMES,
  EMAIL_SECTION_KEY_NAME_TO_SUBTITLE,
  donationTypeLabels,
  donationTypes,
  templateTypes,
} from '../../core/constants';
import appSettings from '../../app-settings';
import { createTemplate, getTemplate, uploadHtml, updateTemplate } from '../../api';
import { getNetworkError, shouldObscureContacts } from '../../core/utils';

const bodyValues = {
  backgroundColor: '#ffffff',
  contentWidth: '600px',
  fontFamily: {
    label: 'Lato',
    value: "'Lato', Tahoma, Verdana, sans-serif",
  },
};

const getDonationLink = (subdomain, params = {}) => {
  const queryParams = new URLSearchParams(params);
  return `https://${subdomain}.${appSettings.donationPageDomain}/?${queryParams.toString()}`;
};

const EmailSectionEditor = ({ sectionKeyName }) => {
  const { account, saveEmailPreferences, setError, updateEmailPreferences } = useAccount();
  const editorRef = useRef(null);
  const [active, setActive] = useState(
    sectionKeyName === 'header' && !account.emailPreferences.sections.header?.templateId, // Open the header section if it's empty
  );
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [template, setTemplate] = useState({});
  const templateId = account.emailPreferences.sections[sectionKeyName]?.templateId;

  const getLinkTypes = (donationPageUrl) => [
    {
      name: 'web',
      enabled: !shouldObscureContacts(account),
    },
    {
      name: 'phone',
      enabled: false,
    },
    {
      name: 'email',
      enabled: false,
    },
    {
      name: 'sms',
      enabled: false,
    },
    {
      name: 'static_donation_page_link',
      label: 'Go To Donation Page',
      attrs: {
        href: `${donationPageUrl}&amount={{amount}}&type={{type}}`,
        target: '_blank',
      },
      fields: [
        {
          name: 'amount',
          label: 'Amount',
          defaultValue: account.emailPreferences.donateButton.amount,
          inputType: 'number',
          placeholderText: null,
        },
        {
          name: 'type',
          label: 'Type',
          defaultValue: account.emailPreferences.donateButton.type,
          inputType: null,
          placeholderText: null,
          options: [
            { value: donationTypes.recurring, label: donationTypeLabels.recurring },
            { value: donationTypes.oneTime, label: donationTypeLabels[donationTypes.oneTime] },
          ],
        },
      ],
    },
  ];

  // Options are mostly the same as the full campaign email editor
  // Disable some tabs and tools to simplify
  // Limit to one row to make it easier to extract and merge the HTML and design into a full email template
  const editorOptions = {
    projectId: 116972,
    features: {
      ai: false,
    },
    editor: {
      minRows: 1,
      maxRows: 1,
    },
    tabs: {
      blocks: {
        enabled: false,
      },
      body: {
        enabled: false,
      },
    },
    tools: {
      button: {
        properties: {
          buttonColors: {
            value: {
              color: account.emailPreferences.donateButton.textColor,
              backgroundColor: account.emailPreferences.donateButton.backgroundColor,
              hoverColor: account.emailPreferences.donateButton.textColor,
              hoverBackgroundColor: account.emailPreferences.donateButton.backgroundColor,
            },
          },
          borderRadius: {
            value: '0px',
          },
          text: {
            value: account.emailPreferences.donateButton.buttonText,
          },
          href: {
            value: {
              name: 'static_donation_page_link',
              attrs: {
                href: `${getDonationLink(account?.donationPageSubdomain, {
                  amount: account.emailPreferences.donateButton.amount,
                  type: account.emailPreferences.donateButton.type,
                })}`,
                target: '_blank',
              },
            },
          },
        },
      },
      rows: {
        enabled: false,
      },
      menu: {
        enabled: false,
      },
    },
    version: '1.63.2',
  };

  const getSectionTemplate = useCallback(async () => {
    if (!templateId) {
      setLoading(false);
      return;
    }
    setLoading(true);
    try {
      const template = await getTemplate(templateId, '1');
      setTemplate(template);
      setLoading(false);
    } catch (err) {
      Sentry.captureException(err);
      setActive(false);
      setError(getNetworkError(err));
    }
  }, [setError, templateId]);

  useEffect(() => {
    if (!active) return;
    void getSectionTemplate();
  }, [active, getSectionTemplate]);

  const exportHtml = useCallback(async () => {
    return new Promise((resolve, reject) => {
      if (!editorRef.current) reject();
      else editorRef.current.exportHtml((data) => resolve(data.html));
    });
  }, []);

  const exportDesign = useCallback(async () => {
    return new Promise((resolve, reject) => {
      if (!editorRef.current) reject();
      else editorRef.current.saveDesign((design) => resolve(design));
    });
  }, []);

  const onLoad = async () => {
    if (template.design) {
      editorRef.current?.loadDesign(JSON.parse(template.design));
    }
    const donationPageUrl = getDonationLink(account?.donationPageSubdomain);
    editorRef.current?.editor.setBodyValues(bodyValues);
    editorRef.current?.editor.setLinkTypes(getLinkTypes(donationPageUrl));
  };

  const saveTemplate = useCallback(async () => {
    try {
      setSaving(true);
      const html = await exportHtml();
      const design = await exportDesign();

      if (!html || isEmpty(design)) {
        throw new Error('Email template cannot be empty, please try again.');
      }

      const { templateId, templateVersion } = template;
      const shouldCreate = !templateId && !templateVersion;

      const { uploadUrl, ...savedTemplate } = await (shouldCreate
        ? createTemplate(JSON.stringify(design), { type: templateTypes.section })
        : updateTemplate(templateId, templateVersion, design));

      await uploadHtml(uploadUrl, html);

      if (shouldCreate) {
        // We don't need to update the email preferences if we're just updating the template
        updateEmailPreferences({
          sections: {
            [sectionKeyName]: {
              templateId: savedTemplate.templateId,
            },
          },
        });
        await saveEmailPreferences();
      }
      setTemplate(savedTemplate);
      setActive(false);
    } catch (err) {
      Sentry.captureException(err);
      setError(getNetworkError(err));
    }

    setSaving(false);
  }, [exportHtml, exportDesign, sectionKeyName, saveEmailPreferences, setError, template, updateEmailPreferences]);

  return (
    <>
      <div
        className="flex items-center justify-between bg-gray-10 px-4 py-3"
        role={active ? undefined : 'button'}
        tabIndex="0"
        onClick={active ? noop : () => setActive(true)}
      >
        <div>
          <h3 className="text-lg font-semibold capitalize dark:text-white-100">Email {sectionKeyName}</h3>
          <p className="text-base">{EMAIL_SECTION_KEY_NAME_TO_SUBTITLE[sectionKeyName]}</p>
        </div>
        {active ? (
          <div className="flex items-center space-x-3">
            <Button title="Cancel" disabled={loading || saving} onClick={() => setActive(false)} />
            <Button title="Save Changes" color="primary" disabled={loading} loading={saving} onClick={saveTemplate} />
          </div>
        ) : (
          <IconChevronDown size={20} />
        )}
      </div>
      <Collapse in={active}>
        <div className="relative min-h-[400px] py-6">
          {loading && <Loader />}
          {active && !loading && (
            <EmailEditor ref={editorRef} options={editorOptions} onReady={onLoad} minHeight={400} />
          )}
        </div>
      </Collapse>
    </>
  );
};

EmailSectionEditor.propTypes = {
  sectionKeyName: PropTypes.oneOf(Object.values(EMAIL_SECTION_KEY_NAMES)).isRequired,
};

export default EmailSectionEditor;
