/* eslint-disable react/prop-types */
import { useCallback, useMemo } from 'react';
import { useReactTable, getCoreRowModel, getPaginationRowModel, flexRender } from '@tanstack/react-table';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { IconEye, IconCreditCardRefund, IconMailForward, IconCurrencyDollarOff } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { TableRowActions } from 'ui';

import { parseDonationAmount } from '../../core/utils';
import { DONATION_ACTIONS, donationStatus, donationTypes } from '../../core/constants';
import CustomPagination from '../tables/CustomPagination';
import { useDonations } from '../../store/donations/hook';
import StatusLabel from './StatusLabel';
import DonationType from './DonationType';

const getActionIcon = (action) => {
  switch (action) {
    case DONATION_ACTIONS.viewContact:
      return <IconEye size={20} />;
    case DONATION_ACTIONS.refund:
      return <IconCreditCardRefund size={20} />;
    case DONATION_ACTIONS.resendReceipt:
      return <IconMailForward size={20} />;
    case DONATION_ACTIONS.cancelSubscription:
      return <IconCurrencyDollarOff size={20} />;
    default:
      return null;
  }
};

const getActions = (donation) => {
  const actions = [DONATION_ACTIONS.viewContact];
  if (donation.status !== donationStatus.refunded) {
    actions.push(DONATION_ACTIONS.resendReceipt, DONATION_ACTIONS.refund);
  }
  if (donation.type === donationTypes.recurring && donation.paymentInfo?.subscription?.status !== 'canceled') {
    actions.push(DONATION_ACTIONS.cancelSubscription);
  }
  return actions;
};

const DonationsTable = ({ data }) => {
  const navigate = useNavigate();
  const { refundDonation, resendReceipt, cancelSubscription } = useDonations();

  const handleSelectAction = useCallback(
    (action, donation) => {
      switch (action) {
        case DONATION_ACTIONS.viewContact:
          navigate(`/donations/${donation.donorId}`);
          break;
        case DONATION_ACTIONS.refund:
          refundDonation(donation.donationId);
          break;
        case DONATION_ACTIONS.resendReceipt:
          resendReceipt(donation.donationId);
          break;
        case DONATION_ACTIONS.cancelSubscription:
          cancelSubscription(donation.donationId);
          break;
        default:
          break;
      }
    },
    [cancelSubscription, navigate, refundDonation, resendReceipt],
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: 'createdAt',
        header: () => 'Date',
        cell: (info) => format(new Date(info.getValue() * 1000), 'MM.dd.yyyy'), // createdAt is in seconds,
        minSize: 110,
      },
      {
        accessorKey: 'netAmount',
        header: () => 'Amount',
        footer: ({ table }) => {
          const { rows } = table.getRowModel();
          if (!rows.length) return '';

          const total = rows.reduce((sum, row) => sum + row.original.netAmount, 0);
          return <strong>{parseDonationAmount(total / 100)}</strong>;
        },
        cell: (info) => parseDonationAmount(info.getValue() / 100), // netAmount is in cents,
        minSize: 110,
      },
      {
        accessorKey: 'type',
        header: () => 'Type',
        cell: (info) => <DonationType {...info.row.original} />,
        minSize: 110,
      },
      {
        accessorKey: 'status',
        header: () => 'Status',
        cell: (info) => <StatusLabel status={info.getValue()} />,
        minSize: 110,
      },
      {
        accessorKey: 'donorName',
        header: () => 'Donor',
        cell: (info) => info.getValue(),
        minSize: 240,
      },
      {
        accessorKey: 'campaignName',
        header: () => 'Campaign',
        minSize: 280,
      },
      {
        accessorKey: 'donationId',
        header: () => '',
        cell: (info) => (
          <TableRowActions
            actions={getActions(info.row.original)}
            onSelectAction={(action) => handleSelectAction(action, info.row.original)}
            getActionIcon={getActionIcon}
          />
        ),
        size: 86,
      },
    ],
    [handleSelectAction],
  );

  const table = useReactTable({
    data,
    columns,
    pageCount: data.totalPages,
    defaultColumn: {
      size: 'fit-content',
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <div className="space-y-6">
      <div className="relative overflow-x-auto sm:rounded-lg">
        <table className="w-full text-left text-base text-gray-950 dark:text-gray-400">
          <thead className="bg-white-100 text-sm uppercase text-gray-400 dark:bg-gray-700 dark:text-gray-400">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className="px-4 py-3"
                      style={{ width: header.column.columnDef.size, minWidth: header.column.columnDef.minSize }}
                    >
                      {header.isPlaceholder ? null : (
                        <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr
                  key={row.id}
                  className="h-[52px] border-b border-gray-50 bg-white-100 last:border-b-2 hover:bg-gray-10 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600"
                >
                  {row.getVisibleCells().map((cell) => {
                    const sentryMasked = cell.column.id === 'donorName';
                    // 'donationId' corresponds to the actions column - reduce horizontal padding to meet the width style condition
                    const paddingClassName = cell.column.id === 'donationId' ? 'p-2' : 'py-2 px-4';
                    return (
                      <td
                        key={cell.id}
                        className={`${paddingClassName} ${sentryMasked ? 'sentry-mask' : ''}`}
                        style={{ width: cell.column.columnDef.size, minWidth: cell.column.columnDef.minSize }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {data.length === 0 &&
              Array(5)
                .fill('')
                .map((row, index) => (
                  <tr
                    key={`emptyRow-${index}`}
                    className="h-[52px] border-b border-gray-50 bg-white-100 hover:bg-gray-10 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600"
                  >
                    <td className="px-4 py-2" colSpan={15} style={{ minWidth: 110 }}></td>
                  </tr>
                ))}
          </tbody>
          <tfoot className="bg-white-100 font-medium text-gray-950">
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <td
                    key={header.id}
                    className="px-4 py-3"
                    style={{
                      width: header.column.columnDef.size,
                      minWidth: header.column.columnDef.minSize,
                    }}
                  >
                    {flexRender(header.column.columnDef.footer, header.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
      </div>

      <CustomPagination table={table} total={data.length} />
    </div>
  );
};

DonationsTable.propTypes = {
  data: PropTypes.array.isRequired,
};

export default DonationsTable;
